

import { ApolloError } from "@apollo/client"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Card, CardContent, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { NukFormDialog } from "../../../../../core/components/dialogs/nuk-form.dialog"
import { NukFormDialogContext, NukIconTitle } from "../../../../../core/nuk-components"
import { CremationRequestFormCompleted } from "./components/cremation-request-form.completed"
import { CremationRequestFormContent } from "./cremation-request-form.content"
import { cremationRequestFormSchema, cremationRequestFormSchemaType } from './cremation-request-form.schema'
import { executeCremationRequestFormCreate, useCremationRequestFormCreate, useCremationRequestFormCreateEffect } from "./hooks/cremation-request-form-create.hook"

type Props = {
  open: boolean,
  setOpen: Function,
  refetch: Function,

}

export const CremationRequestFormDialog: FC<Props> = ({ open,setOpen,refetch }) => {
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(cremationRequestFormSchema),
    
  });
  
  const [gqlErrors, setGqlErrors] = useState<ApolloError | null | undefined>(null);
  const [completed,setCompleted] = useState<boolean>(false)

  const hookDefaults = {
    setGqlError: setGqlErrors,
    setValue: methods.setValue,
  }

  //Form Hooks
  const [createResource, { data: createdResourceData, loading: createResourceLoading, error: createResourceError }] = useCremationRequestFormCreate();

  //Form Effect Hooks
  useCremationRequestFormCreateEffect({ data: createdResourceData, loading: createResourceLoading, error: createResourceError,refetch: refetch, ...hookDefaults })


  
  //Form submission
  const onSubmit = (data : cremationRequestFormSchemaType) => { 
    console.log(data)
    executeCremationRequestFormCreate(createResource,data)
  };

  useEffect(() =>{
    if(open==false && createdResourceData){
      setCompleted(false)
    }


  },[open])



  useEffect(() =>{
    if(createdResourceData){
      setCompleted(true)
      methods.reset()
    }
  },[createdResourceData])

  

  return (
    <NukFormDialogContext.Provider value={{
      loading: false,
      open:open,
      submitted: createResourceLoading,
      gqlErrors: gqlErrors,
      formMethods: methods,
      completed: completed,
      onSubmit: onSubmit,
      setOpen: setOpen,
    }}>
      <FormProvider {...methods}  >
        
        <NukFormDialog
          title="Nuova richiesta di cremazione"
          size="md"
          completedViewComponent={
            <CremationRequestFormCompleted data={createdResourceData}/>
          }
        >
          <CremationRequestFormContent />
        </NukFormDialog>
      </FormProvider>
    </NukFormDialogContext.Provider>
  )
}