import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { INukFormHookEffectParams } from '../../../../../../core/hooks/nuk-form-hooks.interfaces';
import { NukTimeUtils } from '../../../../../../core/utils/nuk-time-utils';
import { UpdateCremationMutation, UpdateMedicalCremationMutation, useUpdateCremationMutation, useUpdateMedicalCremationMutation } from '../../../../../../graphql/nuk-graphql-main';
import { cremationsResource as resourceConfig } from '../../../../cremations.resource';
import { Package } from '../../cremation-request-medical/cremation-request-form-medical.dialog';
import { cremationEditorFormUpdateSchemaType } from "../cremation-editor-form.schema";
/**
 * Cremation Update - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useCremationFormEditorUpdateMedical = () =>{
    return useUpdateMedicalCremationMutation();
}

/**
 * Cremation Update - Executes the update function
 * @param service 
 * @param values 
 */
export const executeMedicalCremationFormEditorUpdate = (service: Function,id: number,values: cremationEditorFormUpdateSchemaType,packets: Package[]) => {
    //Additional validation can be done here.
    //Launching service

    service({
        variables: {
            id: id,
            input: {
                alfCompanyId: 20,
                status: values.status,
                deliveryPipeline: values.deliveryPipeline,
                packets: packets,
                //CFL Body Delivery
                estimatedCflDeliveryAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.estimatedCflDeliveryAtDate,
                    time: values.estimatedCflDeliveryAtTime,
                }),
            
                enteredCflCellAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.enteredCflCellAtDate,
                    time: values.enteredCflCellAtTime,
                }),
                enteredCflCell: values.enteredCflCell,
                exitedCflCellAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.exitedCflCellAtDate,
                    time: values.exitedCflCellAtTime,
                }),

                //BZONA Main Actions
                estimatedDeliveryAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.estimatedDeliveryAtDate,
                    time: values.estimatedDeliveryAtTime,
                }),
                enteredCellAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.enteredCellAtDate,
                    time: values.enteredCellAtTime,
                }),
                enteredCell: values.enteredCell,
                exitedCellAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.exitedCellAtDate,
                    time: values.exitedCellAtTime,
                }),
                startedAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.startedAtDate,
                    time: values.startedAtTime,
                }),
                completedAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.completedAtDate,
                    time: values.completedAtTime,
                }),
                collectedAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.collectedAtDate,
                    time: values.collectedAtTime,
                }),
                deliveryEmployee: values.deliveryEmployee,
                collectedBy: values.collectedBy,
            }
        }
    })
}


/**
 * Cremation Update - Post Effect
 * @param params 
 */
export  const useCremationMedicalFormEditorUpdateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,setResourceLabel } = params
    useEffect(() => {
        if(data){
            //Clearing previous gql errors
            setGqlError(null)
            //Setting resource label
            setResourceLabel(`Cremazione resti medici - ${data.updateMedicalCremation.alfId}`)
            //Toasting success
            toast.success(`Cremazione resti medici - ${data.updateMedicalCremation.alfId} aggiornata.`)
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User update - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: UpdateMedicalCremationMutation | null | undefined}