import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { INukFormHookEffectParams } from '../../../../../../core/hooks/nuk-form-hooks.interfaces';
import { NukTimeUtils } from '../../../../../../core/utils/nuk-time-utils';
import { UpdateCremationMutation, useUpdateCremationMutation } from '../../../../../../graphql/nuk-graphql-main';
import { cremationsResource as resourceConfig } from '../../../../cremations.resource';
import { cremationEditorFormUpdateSchemaType } from "../cremation-editor-form.schema";
/**
 * Cremation Update - Hook Wrapper
 * @param params 
 * @returns 
 */
export  const useCremationFormEditorUpdate = () =>{
    return useUpdateCremationMutation();
}

/**
 * Cremation Update - Executes the update function
 * @param service 
 * @param values 
 */
export const executeCremationFormEditorUpdate = (service: Function,id: number,values: cremationEditorFormUpdateSchemaType) => {
    //Additional validation can be done here.
    //Launching service  
    service({
        variables: {
            id: id,
            input: {
                alfCompanyId: Number(values.alfCompanyId),
                status: values.status,
                bellinzonaAssignment: values.bellinzonaAssignment,
                deliveryPipeline: values.deliveryPipeline,
                  //CFL Body Delivery
                  estimatedCflDeliveryAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.estimatedCflDeliveryAtDate,
                    time: values.estimatedCflDeliveryAtTime,
                }),
            
                enteredCflCellAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.enteredCflCellAtDate,
                    time: values.enteredCflCellAtTime,
                }),
                enteredCflCell: values.enteredCflCell,
                exitedCflCellAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.exitedCflCellAtDate,
                    time: values.exitedCflCellAtTime,
                }),
                //BZONA Main Actions
                estimatedDeliveryAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.estimatedDeliveryAtDate,
                    time: values.estimatedDeliveryAtTime,
                }),
                enteredCellAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.enteredCellAtDate,
                    time: values.enteredCellAtTime,
                }),
                enteredCell: values.enteredCell,
                exitedCellAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.exitedCellAtDate,
                    time: values.exitedCellAtTime,
                }),
                startedAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.startedAtDate,
                    time: values.startedAtTime,
                }),
                completedAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.completedAtDate,
                    time: values.completedAtTime,
                }),
                collectedAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.collectedAtDate,
                    time: values.collectedAtTime,
                }),
                // CFL Fase two
                backDeliveryQrCflScannedAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.backDeliveryQrCflScannedAtDate,
                    time: values.backDeliveryQrCflScannedAtTime,
                }),
                collectionQrCflScannedAt: NukTimeUtils.updateDateWithTimeValuesFromSelect({
                    date: values.collectionQrCflScannedAtDate,
                    time: values.collectionQrCflScannedAtTime,
                }),
                deliveryEmployee: values.deliveryEmployee,
                collectedBy: values.collectedBy,
                firstName: values.firstName,
                lastName: values.lastName,
                type: values.type,
                medicalCertificateSigned: values.medicalCertificateSigned,
                exhumationContainer: values.exhumationContainer,
                medicalCertificateDelivery: values.medicalCertificateDelivery,
                birth: values.birth,
                birthYearOnly: (values.birthYearOnly === 'true') ? true : false,
                death: values.death,
                deathYearOnly:  (values.deathYearOnly === 'true') ? true : false,
                countryCode: values.countryCode,
                zipCode: `${values.zipCode}`,
                city: values.city,
                customization: values.customization,
                customizationDetail: values.customizationDetail,
                invoiceType: values.invoiceType,
        
            }
        }
    })
}


/**
 * Cremation Update - Post Effect
 * @param params 
 */
export  const useCremationFormEditorUpdateEffect = (params: EffectParams) => {
    const { data,error,setGqlError,setResourceLabel } = params
    useEffect(() => {
        if(data){
            //Clearing previous gql errors
            setGqlError(null)
            //Setting resource label
            setResourceLabel(`${data.updateCremation.firstName} ${data.updateCremation.lastName}`)
            //Toasting success
            toast.success(`${resourceConfig.nameSingular} ${data.updateCremation.firstName} ${data.updateCremation.lastName} aggiornata.`)
        } else if(error){
            //Setting Gql Errors
            setGqlError(error);
        }
    },[data,error])
}

//User update - Interfaces
interface EffectParams extends INukFormHookEffectParams {data: UpdateCremationMutation | null | undefined}