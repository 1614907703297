import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InfoIcon from "@mui/icons-material/Info";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import {
  Button,
  Card,
  CardContent, Collapse,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  CalendarPicker,
  CalendarPickerProps,
} from "@mui/x-date-pickers/CalendarPicker";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { FieldValues, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NukFileManagerFileT } from "../../../../../core/components/files/nuk-file-manager/NukFileManager";
import { NukFormCountrySelect } from "../../../../../core/components/forms/nuk-form-country-select/nuk-form-country-select";
import { NukFormDateField } from "../../../../../core/components/forms/nuk-form-date-field";
import { NukFormSelectField } from "../../../../../core/components/forms/nuk-form-select-field";
import { NukLabel } from "../../../../../core/components/labels/nuk-label";
import { NukIconTitle } from "../../../../../core/components/titles/nuk-icon-title";
import {
  NukFileManager,
  NukFormCheckboxField,
  NukFormContext, NukFormRadioGroupField,
  NukFormTextField
} from "../../../../../core/nuk-components";
import {
  GetCremationQuery,
  useListContentsQuery,
  useListOriginsQuery,
} from "../../../../../graphql/nuk-graphql-main";
import { CompaniesSelectField } from "../../../../../modules/companies/components/companies-select";
import { CremationStatus } from "../../../constants/cremation-status.enum";
import {
  CREMATION_MEDICAL_CERTIFICATE_REMOVE,
  CREMATION_MEDICAL_CERTIFICATE_STREAM,
  CREMATION_MEDICAL_CERTIFICATE_UPLOAD,
} from "../../../service/cremations.storage.endpoints";
import { CremationStatusChip } from "../../chips/cremation-status.chip";
import { CremationCityField } from "../../fields/cremation-city-field";
import { CremationZipCodeField } from "../../fields/cremation-zipcode-field";
import {
  Add, Inventory,
  LocalShipping, PendingActions
} from "@mui/icons-material";
import { Package } from "../cremation-request-medical/cremation-request-form-medical.dialog";
import { PackageContentEntry } from "../cremation-request-medical/components/package-content-entry";

import { CremationEditorFormBellinzonaBlock } from "./cremation-editor-bellinzona-block";
import { NukIconTitleContrast } from "../../../../../core/components/titles/nuk-icon-title-contrast";
import { CremationEditorCFLBodyBlock } from "./cremation-editor-cfl-body-block";
import { CremationEditorCFLAshesBlock } from "./cremation-editor-cfl-ashes-block";
import { NukFormHiddenField } from "../../../../../core/components/forms/nuk-form-hidden-field";


type Props = {
  resourceData: GetCremationQuery | null | undefined;
  watch: UseFormWatch<FieldValues>;
  packages: Package[];
  addPackage: Function;
  updatePackage: Function;
  removePackage: Function;
};

//Delivery Pipelines - Pregassona
const deliveryPipelineCFL: any[] = [
  {
    value: CremationStatus.REGISTERED,
    renderValue: <CremationStatusChip value={CremationStatus.REGISTERED} />,
  },
  {
    value: CremationStatus.DELIVERED_CFL,
    renderValue: (
      <CremationStatusChip
        value={CremationStatus.DELIVERED_CFL}
        location={`Pregassona`}
      />
    ),
  },
  {
    value: CremationStatus.MOVING_FROM_CFL,
    renderValue: (
      <CremationStatusChip
        value={CremationStatus.MOVING_FROM_CFL}
        location={`Verso Carasso`}
      />
    ),
  },
  {
    value: CremationStatus.DELIVERED,
    renderValue: (
      <CremationStatusChip
        value={CremationStatus.DELIVERED}
        location={`Carasso`}
      />
    ),
  },
  {
    value: CremationStatus.STARTED,
    renderValue: <CremationStatusChip value={CremationStatus.STARTED} 
    location={`Carasso`}
    />,
  },
  {
    value: CremationStatus.COMPLETED,
    renderValue: (
      <CremationStatusChip
        value={CremationStatus.COMPLETED}
        location={`Carasso`}
      />
    ),
  },
  {
    value: CremationStatus.COLLECTED,
    renderValue: <CremationStatusChip value={CremationStatus.COLLECTED}
      location={`Carasso`}
    />,
  },
  {
    value: CremationStatus.MOVING_TO_CFL,
    renderValue: (
      <CremationStatusChip
        value={CremationStatus.MOVING_TO_CFL}
        location={`Verso Pregassona`}
      />
    ),
  },
  {
    value: CremationStatus.DELIVERED_BACK_CFL,
    renderValue: (
      <CremationStatusChip
        value={CremationStatus.DELIVERED_BACK_CFL}
        location={`Pregassona`}
      />
    ),
  },
  {
    value: CremationStatus.COLLECTED_CFL,
    renderValue: (
      <CremationStatusChip
        value={CremationStatus.COLLECTED_CFL}
        location={`Pregassona`}
      />
    ),
  },
 
];
//Delivery Pipelines - Bellinzona
const deliveryPipelineBZONA: any[] = [
  {
    value: CremationStatus.REGISTERED,
    renderValue: <CremationStatusChip value={CremationStatus.REGISTERED} />,
  },

  {
    value: CremationStatus.DELIVERED,
    renderValue: (
      <CremationStatusChip
        value={CremationStatus.DELIVERED}
        location={`Carasso`}
      />
    ),
  },
  {
    value: CremationStatus.STARTED,
    renderValue: <CremationStatusChip value={CremationStatus.STARTED} />,
  },
  {
    value: CremationStatus.COMPLETED,
    renderValue: (
      <CremationStatusChip
        value={CremationStatus.COMPLETED}
        location={`Carasso`}
      />
    ),
  },

  {
    value: CremationStatus.COLLECTED,
    renderValue: <CremationStatusChip value={CremationStatus.COLLECTED} />,
  },
];

/**
 * Cremation editor form content
 * All the fields are inside this component
 * @param param0
 * @returns
 */
export const CremationEditorFormContent: FC<Props> = ({
  watch,
  resourceData,
  packages,
  addPackage,
  updatePackage,
  removePackage,
}) => {
  const { mode, trns } = useContext(NukFormContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const smBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  //Watching
  const watchType = watch("type", "NEW");
  const watchBirthYearOnly = watch("birthYearOnly", false);
  const watchDeathYearOnly = watch("deathYearOnly", false);
  const watchCustomization = watch("customization", "STANDARD");
  const watchBellinzonaAssignment = watch("bellinzonaAssignment");
  const watchMedicalCertificateSigned = watch(
    "medicalCertificateSigned",
    false
  );
  const CustomCalendarPicker = styled(CalendarPicker)<CalendarPickerProps<any>>`
    margin: 0;
    width: 100%;
  `;
  const packageTotalValues: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  //Origins
  const {
    loading: originsLoading,
    data: originsData,
    error: originsError,
  } = useListOriginsQuery({
    variables: {
      input: {
        pagination: {
          page: 1,
          limit: 1000,
        },
      },
    },
  });

  //Types
  const {
    loading: contentsLoading,
    data: contentsData,
    error: contentsError,
  } = useListContentsQuery({
    variables: {
      input: {
        pagination: {
          page: 1,
          limit: 1000,
        },
      },
    },
  });

  const [date, setDate] = useState<Date | null>(null);

  const [files, setFiles] = useState<NukFileManagerFileT[]>([]);

  useEffect(() => {
    if (resourceData) {
      if (resourceData.cremation.medicalCertificateFile) {
        setFiles([
          {
            label: `Certificato medico timbrato`,
            name: "medical-certificate-signed",
          },
        ]);
      }
    }
  }, [resourceData]);

  return (
    <Fragment>
      {resourceData?.cremation.type !== "MEDICAL_REMAINS" && (
        <Card
          sx={{
            mb: 1,
            background: watchBellinzonaAssignment ? "#db1f16" : "white",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ display: "flex", padding: 2, alignItems: "center" }}
          >
            <div
              style={{
                width: "75%",
                color: watchBellinzonaAssignment ? "white" : "black",
              }}
            >
              <NukFormCheckboxField
                label={smBreakPoint ? "Incarico  città di Bellinzona" : "Incarico da parte della città di Bellinzona"}  
                name="bellinzonaAssignment"
              />
            </div>
            <div style={{ width: "25%" }}>
              <img
                src={`/static/images/bellinzona-crest-${
                  watchBellinzonaAssignment ? `enabled` : `disabled`
                }.svg`}
                style={{ width: 65, height: 65, float: "right" }}
              />
            </div>
          </Grid>
        </Card>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  {resourceData?.cremation.type !== "MEDICAL_REMAINS" ? (
                    <CompaniesSelectField  />
                  ) : (
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      sx={{ mt: 2, pb: 1 }}
                    >
                      Valbenne SA
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <NukFormSelectField
                    customRender
                    name="status"
                    label="Stato"
                    defaultValue="1"
                    items={
                      resourceData?.cremation.deliveryPipeline == "CFL"
                        ? deliveryPipelineCFL
                        : deliveryPipelineBZONA
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6}></Grid>
      </Grid>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Card sx={{ flex: 1 }}>
            {resourceData?.cremation.type !== "MEDICAL_REMAINS" ? (
              <CardContent sx={{ pt: 0 }}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                  <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
                    <NukIconTitle
                      label="Dati defunto"
                      icon={<AccountCircleIcon />}
                      textTransform="uppercase"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <NukFormTextField
                      name="lastName"
                      label={t("lastName", { ns: "general" })}
                      sx={{
                        "& input": {
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          fontSize: 24,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <NukFormTextField
                      name="firstName"
                      label={t("firstName", { ns: "general" })}
                      sx={{
                        "& input": {
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          fontSize: 24,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <NukFormDateField
                      name="birth"
                      label={
                        watchBirthYearOnly === "true"
                          ? t("birthYear", { ns: trns })
                          : t("birthDate", { ns: trns })
                      }
                      views={
                        watchBirthYearOnly === "true"
                          ? ["year"]
                          : ["year", "month", "day"]
                      }
                      inputFormat={
                        watchBirthYearOnly === "true" ? "yyyy" : "dd.MM.yyyy"
                      }
                    />

                    <NukFormRadioGroupField
                      size="small"
                      name="birthYearOnly"
                      items={[
                        {
                          label: t("fullDate", { ns: trns }),
                          value: "false",
                          default: true,
                        },
                        {
                          label: t("yearOnly", { ns: trns }),
                          value: "true",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <NukFormDateField
                      name="death"
                      label={
                        watchDeathYearOnly === "true"
                          ? t("deathYear", { ns: trns })
                          : t("deathDate", { ns: trns })
                      }
                      views={
                        watchDeathYearOnly === "true"
                          ? ["year"]
                          : ["year", "month", "day"]
                      }
                      inputFormat={
                        watchDeathYearOnly === "true" ? "yyyy" : "dd.MM.yyyy"
                      }
                    />

                    <NukFormRadioGroupField
                      name="deathYearOnly"
                      size="small"
                      items={[
                        {
                          label: t("fullDate", { ns: trns }),
                          value: "false",
                          default: true,
                        },
                        {
                          label: t("yearOnly", { ns: trns }),
                          value: "true",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <NukFormCountrySelect
                      name="countryCode"
                      label="Nazionalità"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <CremationZipCodeField />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <CremationCityField />
                  </Grid>
                </Grid>
              </CardContent>
            ) : (
              <CardContent>
                <Grid container columnSpacing={2} rowSpacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <NukIconTitle
                      label="Contenuto"
                      icon={<Inventory />}
                      textTransform="uppercase"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    {packages.map(
                      (p: Package, i: number) =>
                        originsData &&
                        contentsData && (
                          <PackageContentEntry
                            key={`package-${i}`}
                            data={p}
                            index={i}
                            remove={removePackage}
                            update={updatePackage}
                            totalValues={packageTotalValues}
                            originValues={originsData}
                            contentValues={contentsData}
                          />
                        )
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<Add />}
                      onClick={() => addPackage()}
                    >
                      Aggiungi
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Grid>

        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Card sx={{ flex: 1 }}>
            <CardContent>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <NukIconTitle
                    label="Dettagli Cremazione"
                    icon={<LocalFireDepartmentIcon />}
                    textTransform="uppercase"
                  />
                </Grid>

         
                {resourceData?.cremation.type !== "MEDICAL_REMAINS" && (
                  <Grid item sm={12} md={12}>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item sm={12} md={12}>
                        <Card>
                          <CardContent>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                              <Grid item xs={12}>
                                <NukLabel
                                  label="Dettaglio"
                                  icon={<InfoIcon />}
                                ></NukLabel>
                              </Grid>
                              <Grid item xs={12}>
                                <NukFormRadioGroupField
                                  name="type"
                                  label="Tipologia"
                                  items={[
                                    {
                                      label: t("cremationTypeNEW", {
                                        ns: trns,
                                      }),
                                      value: "NEW",
                                      default: true,
                                    },
                                    {
                                      label: t("cremationTypeEXHUMED", {
                                        ns: trns,
                                      }),
                                      value: "EXHUMED",
                                    },
                                    {
                                      label:
                                        "Cremazione di una salma con cofano di zinco",
                                      value: "ZINC",
                                    },
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Collapse in={watchType === "EXHUMED"}>
                                  <NukFormRadioGroupField
                                    name="exhumationContainer"
                                    label="Contenitore dei resti esumati"
                                    items={[
                                      {
                                        label: t("exhumationContainerLARGE", {
                                          ns: trns,
                                        }),
                                        value: "LARGE",
                                        default: true,
                                      },
                                      {
                                        label: t("exhumationContainerSMALL", {
                                          ns: trns,
                                        }),
                                        value: "SMALL",
                                      },
                                    ]}
                                  />
                                </Collapse>
                              </Grid>
                              <Grid item xs={12}>
                                <NukFormTextField
                                  name="deliveryEmployee"
                                  label="Nominativo incaricato che ha inoltrato la richiesta"
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item sm={12} md={12}>
                        <Card>
                          <CardContent>
                            <NukLabel
                              label="Certificato Medico"
                              icon={<MedicalInformationIcon />}
                            ></NukLabel>

                            <NukFormRadioGroupField
                              sx={{ mt: 1, mb: 2 }}
                              name="medicalCertificateDelivery"
                              label="Consegna del certificato medico"
                              defaultValue="EMAIL"
                              items={[
                                {
                                  label: t("medicalCertificateDeliveryEMAIL", {
                                    ns: trns,
                                  }),
                                  value: "EMAIL",
                                  default: true,
                                },
                                {
                                  label: t("medicalCertificateDeliveryHAND", {
                                    ns: trns,
                                  }),
                                  value: "HAND",
                                },
                              ]}
                            />

                            <NukFormCheckboxField
                              name="medicalCertificateSigned"
                              label="Certificato medico timbrato e ricevuto"
                            />

                            <Collapse in={watchMedicalCertificateSigned}>
                              {resourceData && (
                                <NukFileManager
                                  resourceId={resourceData.cremation.id}
                                  endpoints={{
                                    upload:
                                      CREMATION_MEDICAL_CERTIFICATE_UPLOAD,
                                    stream:
                                      CREMATION_MEDICAL_CERTIFICATE_STREAM,
                                    remove:
                                      CREMATION_MEDICAL_CERTIFICATE_REMOVE,
                                  }}
                                  uploadEnabled={files.length == 0}
                                  onUploadCompleted={() => {
                                    setFiles([
                                      {
                                        label: `Certificato medico timbrato`,
                                        name: "medical-certificate-signed",
                                      },
                                    ]);
                                  }}
                                  files={files}
                                  onFileRemoved={() => {
                                    setFiles([]);
                                  }}
                                />
                              )}
                            </Collapse>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Card sx={{ flex: 1, backgroundColor: "#388e3c" }}>
            <CardContent>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={2}
                sx={{ backgroundColor: "#388e3c" }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <NukIconTitleContrast
                    label="Logistica"
                    icon={<PendingActions />}
                    textTransform="uppercase"
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={
                    resourceData?.cremation.type !== "MEDICAL_REMAINS" ? 6 : 12
                  }
                ></Grid>

                <Grid item sm={12} md={12} >
                  <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                  <Card sx={{ mb: 1}}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", padding: 2, alignItems: "center" }}
                    >
      <Grid container columnSpacing={2} rowSpacing={2} sx={{ padding: 2 }}>
      <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{
                            display: "flex",

                            alignItems: "center",
                          }}
                        >
                          <NukLabel
                            label="&nbsp;Luogo di consegna e ritiro "
                            icon={<LocalShipping />}
                          ></NukLabel>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              padding: 2,
                              fontWeight: "bold",
                              fontSize: 24,
                            }}
                          >
                            {resourceData?.cremation.deliveryPipeline == "CFL"
                              ? `PREGASSONA`
                              : `CARASSO`}
                          </div>

                          <NukFormHiddenField
                            name="deliveryPipeline"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>


                    {/* CFL/PREGASSONA BODY EVENTS */}
                    {resourceData?.cremation.deliveryPipeline == "CFL" &&
                    <CremationEditorCFLBodyBlock resourceData={resourceData} />
                    }

                    {/* BELLINZONA BLOCK */}
                    <CremationEditorFormBellinzonaBlock resourceData={resourceData} watchMedicalCertificateSigned={watchMedicalCertificateSigned}/>
      
                    
                    {/* CFL/PREGASSONA ASHES  EVENTS*/}
                    {resourceData?.cremation.deliveryPipeline == "CFL" &&
                    <CremationEditorCFLAshesBlock resourceData={resourceData} watchMedicalCertificateSigned={watchMedicalCertificateSigned}/>
                    }

                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

function setState(arg0: boolean): [any, any] {
  throw new Error("Function not implemented.");
}
