import { Card, CardContent, Grid, Typography, Chip, Stack, Button, Collapse } from "@mui/material";
import { FC, useState } from "react";
import { NukTextViewLabel } from "../../../../core/components/labels/nuk-text-view-label";
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { NukLabel } from "../../../../core/components/labels/nuk-label";

type Props = {
  data: any
}

export const CremationMailStatusCard: FC<Props> = ({ data }) => {
  const [openErrors, setOpenErrors] = useState<Record<string, boolean>>({});

  const toggleError = (section: string) => {
    setOpenErrors(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const getStatusConfig = (status?: string) => {
    switch (status) {
      case 'SENT':
        return {
          label: 'Inviata',
          icon: <CheckCircleIcon />,
          color: 'success',
          variant: 'filled' as const
        };
      case 'ERRORED':
        return {
          label: 'Errore',
          icon: <ErrorIcon />,
          color: 'error',
          variant: 'filled' as const
        };
      default:
        return {
          label: 'Non inviata',
          icon: <ScheduleIcon />,
          color: 'default',
          variant: 'outlined' as const
        };
    }
  };

  const renderEmailSection = (title: string, sentAt?: string, status?: string, error?: string) => {
    const statusConfig = getStatusConfig(status);
    const sectionKey = title.replace(/\s+/g, '');

    return (
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }} gutterBottom>
            {title}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <NukLabel label="Stato" />
              <Chip
                icon={statusConfig.icon}
                label={statusConfig.label}
                color={statusConfig.color as any}
                variant={statusConfig.variant}
                sx={{ 
                  fontSize: '0.875rem',
                  py: 2,
                  px: 0.75,
                  '& .MuiChip-icon': {
                    fontSize: '1.25rem'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <NukTextViewLabel
                label="Data invio"
                value={sentAt ? new Date(sentAt).toLocaleString('it-IT', {
                  day: '2-digit',
                  month: '2-digit', 
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                }).replace(',', '').replace(/\//g, '.') : '-'}
              />
            </Grid>
            {status === 'ERRORED' && error && (
              <Grid item xs={12}>
                <Button
                  onClick={() => toggleError(sectionKey)}
                  variant="text"
                  color="error"
                  endIcon={openErrors[sectionKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  sx={{ mt: 1 }}
                >
                  {openErrors[sectionKey] ? 'Nascondi errore' : 'Mostra errore'}
                </Button>
                <Collapse in={openErrors[sectionKey]}>
                  <Card variant="outlined" sx={{ mt: 1, bgcolor: 'error.main', color: 'error.contrastText' }}>
                    <CardContent>
                      <Typography
                        sx={{
                          fontFamily: 'monospace',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-all'
                        }}
                      >
                        {error}
                      </Typography>
                    </CardContent>
                  </Card>
                </Collapse>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const renderEmailChips = () => {
    const emails = data?.createdByApplication?.name === 'PGFD' ? [
      { label: 'Azienda', value: data?.overrideCompanyEmail },
      { label: 'Responsabile', value: data?.overrideManagerEmail },
      { label: 'Extra', value: data?.overrideExtraEmail }
    ] : [
      { label: 'Account', value: data?.createdByUser?.email },

    ].filter(email => email.value);

    return emails.length > 0 ? (
      <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
        {emails.map(({ label, value }) => (
          value && (
            <Chip
              key={label}
              icon={<EmailIcon />}
              label={
                <span>
         
                  <span>{value}</span>
                  {' '}
                  <span style={{ color: '#666',fontWeight: 'bold',}}>({label})</span>

                </span>
              }
              variant="outlined" 
              sx={{ borderRadius: 1 }}
            />
          )
        ))}
      </Stack>
    ) : (
      <Typography color="text.secondary">Nessun destinatario configurato</Typography>
    );
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          NOTIFICHE
        </Typography>





      <NukLabel label="Destinatari" />
        {renderEmailChips()}

        {renderEmailSection(
          'Email conferma',
          data?.registrationMailSentAt,
          data?.registrationMailSentStatus,
          data?.registrationMailSentError
        )}

        {renderEmailSection(
          'Email Ceneri Pronte',
          data?.collectionMailSentAt,
          data?.collectionMailSentStatus,
          data?.collectionMailSentError
        )}
      </CardContent>
    </Card>
  );
};
