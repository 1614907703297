import {
  Timeline
} from "@mui/lab";
import { FC, Fragment } from "react";
import {
  Box, useMediaQuery,
  useTheme
} from "@mui/material";
import {
  CremationStatus,
  isStatusEqualOrAfter
} from "../../constants/cremation-status.enum";
import { CremationEventsTimelineItem } from "./cremation-events.timeline-item";
import {
  GetCremationQuery
} from "../../../../graphql/nuk-graphql-main";
import PersonIcon from "@mui/icons-material/Person";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { CremationEventsTimelineItemSlim } from "./cremation-events.timeline-item-slim";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

type Props = {
  data: GetCremationQuery;
};

const oppositeSx = {
  py: 2,
};
const contentSx = {
  py: 2.2,
};


export const CremationEventsTimeline: FC<Props> = ({ data }) => {
    return <Timeline sx={{ "& .MuiTypography-root": { fontSize: 28, mt: -1 } }}>
      <CremationEventsTimelineItem
        status={CremationStatus.REGISTERED}
        location={`Portale Online`}
        managedBy={`Onoranza`}
        time={data.cremation.createdAt}
      />


    


   

      {/* ## CFL CELL EXTIMATED DELIVERY ##########################################################*/}
      {/* Displayed only if status if REGISTERED and replaced by the component below once is DELIVERED_CFL */}
      {(
          data.cremation.status == CremationStatus.REGISTERED
            && data.cremation.deliveryPipeline=='CFL') && (
        <CremationEventsTimelineItem
          status={CremationStatus.DELIVERED_CFL}
          time={data.cremation.estimatedCflDeliveryAt}
          title="Consegna in cella prevista"
          location={`Pregassona`}
         managedBy={`Onoranza`}
          detailItems={
            data.cremation.enteredCflCell
              ? [
                  <Fragment>
                    <AcUnitIcon />
                    Cella {data.cremation.enteredCflCell?.toString().slice(-1)}
                  </Fragment>,
                ]
              : data.cremation.unavailableCflCellAtReservation
                ? [
                    <div style={{color: '#d32f2f',display:'flex',alignItems:'center',fontWeight:'bold'}}>
                      <ReportGmailerrorredIcon/> 
                      Cella non disponibile
                    </div>
                  ]
                : undefined
          }
        />
      )}
      {/* ## END OF CFL CELL EXTIMATED DELIVERY #################################################*/}

      {/* ## CFL DELIVERED ######################################################################*/}
      {(
           isStatusEqualOrAfter(data.cremation.status as CremationStatus, CremationStatus.DELIVERED_CFL)
            && data.cremation.deliveryPipeline=='CFL') && (
        <CremationEventsTimelineItem
          status={CremationStatus.DELIVERED_CFL}
          time={data.cremation.enteredCflCellAt}
          location={`Pregassona`}
          managedBy={`Onoranza`}
          detailItems={
            data.cremation.enteredCflCell
              ? [
                  <Fragment>
                    <AcUnitIcon />
                    Cella {data.cremation.enteredCflCell?.toString().slice(-1)}
                  </Fragment>,
                ]
              : undefined
          }
          qrScanTime={data.cremation.deliveryQrCflScannedAt}

        />
      )}
      {/* ## END OF CFL CELL DELIVERED ########################################################*/}


    {/* ## MOVING FROM CFL  ######################################################################*/}
    {(
         isStatusEqualOrAfter(data.cremation.status as CremationStatus, CremationStatus.MOVING_FROM_CFL)
            && data.cremation.deliveryPipeline=='CFL') && (
        <CremationEventsTimelineItem
          status={CremationStatus.MOVING_FROM_CFL}
          time={data.cremation.pickupQrCflScannedAt}
          location={`Pregassona`}
          locationOverride="Direzione Bellinzona"
          title={data.cremation.status == CremationStatus.MOVING_FROM_CFL ? `Trasporto in corso` : `Trasporto`}
          managedBy={`Team Crematorio`}
   
          qrScanTime={data.cremation.pickupQrCflScannedAt}

        />
      )}
      {/* ## END OF CFL MOVING FROM CFL ########################################################*/}




      {/* ## BZONA CELL EXTIMATED DELIVERY ##########################################################*/}
      {/* Displayed only if status if REGISTERED  and replaced by the component below once is DELIVERED_CFL */}
      {(
            
              data.cremation.status == CremationStatus.REGISTERED ||
              data.cremation.status == CremationStatus.DELIVERED_CFL ||
              data.cremation.status == CremationStatus.MOVING_FROM_CFL
            
          ) && (
        <CremationEventsTimelineItem
          status={CremationStatus.DELIVERED}
          time={data.cremation.estimatedDeliveryAt}
          title="Consegna in cella prevista"
          location={`Carasso`}
          managedBy={(data.cremation.deliveryPipeline == 'CFL') ? `Team Crematorio` : `Onoranza`}
          detailItems={
            data.cremation.enteredCell
              ? [
                  <Fragment>
                    <AcUnitIcon />
                    Cella {data.cremation.enteredCell?.toString().slice(-1)}
                  </Fragment>,
                ]
              : undefined
          }
        />
      )}
      {/* ## END OF BZONA CELL EXTIMATED DELIVERY #################################################*/}


  {/* ## BZONA CELL DELIVERED ##########################################################*/}
      {(
            
        isStatusEqualOrAfter(data.cremation.status as CremationStatus, CremationStatus.DELIVERED)
          
        ) && (
      <CremationEventsTimelineItem
        status={CremationStatus.DELIVERED}
        time={data.cremation.enteredCellAt}
        qrScanTime={data.cremation.deliveryQrScannedAt}
        location={`Carasso`}
        managedBy={(data.cremation.deliveryPipeline == 'CFL') ? `Team Crematorio` : `Onoranza`}
        detailItems={
          data.cremation.enteredCell
            ? [
                <Fragment>
                  <AcUnitIcon />
                  Cella {data.cremation.enteredCell?.toString().slice(-1)}
                </Fragment>,
              ]
            : undefined
        }
      />
    )}
    {/* ## END OF BZONA CELL DELIVERED #################################################*/}
      


      {/* ## BZONA CREMATION STARTED ##########################################################*/}
      {isStatusEqualOrAfter(data.cremation.status as CremationStatus, CremationStatus.STARTED) && (
      <CremationEventsTimelineItem
        status={CremationStatus.STARTED}
        title="Cremazione"
        location={"Carasso"}
        time={data.cremation.startedAt}
        managedBy={`Team Crematorio`}
      />
      )}
      {/* ## END OF BZONA CREMATION STARTED #################################################*/}

      {/* ## BZONA CREMATION COMPLETED ##########################################################*/}
      {isStatusEqualOrAfter(data.cremation.status as CremationStatus, CremationStatus.COMPLETED) && (
      <CremationEventsTimelineItem
        status={CremationStatus.COMPLETED}
        time={data.cremation.completedAt}
        location={"Carasso"}
        managedBy={`Team Crematorio`}
      />
      )}
      {/* ## END OF BZONA CREMATION COMPLETED #################################################*/}


      {/* ## BZONA COLLECTED (END OF BZONA PIPELINE) ##########################################*/}
      { (data.cremation.deliveryPipeline === "BZONA" && isStatusEqualOrAfter(data.cremation.status as CremationStatus, CremationStatus.COLLECTED)) && 
      <CremationEventsTimelineItem
        status={CremationStatus.COLLECTED}
        time={data.cremation.collectedAt}
        detailItems={
          data.cremation.collectedBy
            ? [
                <Fragment>
                  <PersonIcon />
                  {data.cremation.collectedBy}
                </Fragment>,
              ]
            : undefined
        }
        location="Carasso"
        managedBy={`Onoranza`}
        last={true}
        qrScanTime={data.cremation.collectionQrScannedAt}
      />
      }
      {/* ## END OF BZONA COLLECTED ##########################################################*/}

 {/* ## MOVING TO CFL  ######################################################################*/}
 {(
         isStatusEqualOrAfter(data.cremation.status as CremationStatus, CremationStatus.MOVING_TO_CFL)
            && data.cremation.deliveryPipeline=='CFL') && (
        <CremationEventsTimelineItem
          status={CremationStatus.MOVING_TO_CFL}
          time={data.cremation.collectionQrScannedAt}
          location={`Carasso`}
          locationOverride="Direzione Pregassona"
          title={data.cremation.status == CremationStatus.MOVING_TO_CFL ? `Trasporto in corso` : `Trasporto`}
          managedBy={`Team Crematorio`}
   
          qrScanTime={data.cremation.collectionQrScannedAt}

        />
      )}
      {/* ## END OF CFL MOVING TO CFL ########################################################*/}


{data.cremation.deliveryPipeline === "CFL" && 
  isStatusEqualOrAfter(data.cremation.status as CremationStatus, CremationStatus.DELIVERED_BACK_CFL) &&

(
        <CremationEventsTimelineItem
          status={CremationStatus.COMPLETED}
          time={data.cremation.backDeliveryQrCflScannedAt}
          location={"Pregassona"}
          title={(data.cremation.status === CremationStatus.COLLECTED) ? "Consegna ceneri in corso" : `Ceneri pronte`}
          managedBy={`Team Crematorio`}
          qrScanTime={data.cremation.backDeliveryQrCflScannedAt}
          
        />
      )}

{data.cremation.deliveryPipeline === "CFL" && isStatusEqualOrAfter(data.cremation.status as CremationStatus, CremationStatus.COLLECTED_CFL) && (  
        <CremationEventsTimelineItem
          status={CremationStatus.COLLECTED_CFL}
          time={data.cremation.collectionQrCflScannedAt}
          location={"Pregassona"}
          managedBy={`Onoranza`}
          qrScanTime={data.cremation.collectionQrCflScannedAt}
          last
        />
      )}
    </Timeline>
  
   
  
};
