import { Box, capitalize as capitalizeFunc, Typography } from "@mui/material"
import { FC, Fragment } from "react"

type Props = {
    label: string,
    icon?: JSX.Element
}

export const NukLabelTitle : FC<Props> = ({
    label,
    icon,
}) =>{
    return(
            icon ?
                <Box>
                    <Typography 
                        fontSize={20} 
                        fontWeight="bold" 
                        color={(theme) => theme.palette.grey[700]} 
                        textTransform="uppercase"
                        sx={{display:'flex',alignItems:'center',gap:1}}    
                    >
                    {icon}
                    {label}
                    </Typography>
                </Box>
            :
            <Typography fontSize={18} fontWeight="bold" color={(theme) => theme.palette.grey[500]} textTransform="uppercase">
                {label}
            </Typography>

    )
}