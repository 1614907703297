import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Box,
  capitalize,
  Card,
  CardContent,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, Fragment, useContext } from "react";
import {
  CremationStatus,
  CremationStatusColor,
} from "../../constants/cremation-status.enum";
import { CremationStatusIcon } from "../icons/cremation-status.icon";
import { useTranslation } from "react-i18next";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { it } from "date-fns/locale";
import { uniqueId } from "lodash";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import nextId from "react-id-generator";
import { formatInTimeZone } from "date-fns-tz";
import { Handshake, LocalShipping, LocationOn, People, QrCode2, VerifiedUserSharp } from "@mui/icons-material";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

const oppositeSx = {
  py: 2,
};
const contentSx = {
  py: 2.2,
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    marginRight: 5,
  },
}));

type Props = {
  status: CremationStatus;
  time: Date | null;
  detailItems?: [JSX.Element];
  last?: boolean;
  title?: string;
  forceOneHourLess?: boolean;
  location?: string;
  locationOverride?: string;
  qrScanTime?: Date | null;
  managedBy?: string | null;
};

export const CremationEventsTimelineItem: FC<Props> = ({
  status,
  time,
  detailItems = [],
  last = false,
  title,
  forceOneHourLess = false,
  location = null,
  locationOverride = null,
  qrScanTime = null,
  managedBy = null,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smBreakPoint = useMediaQuery(theme.breakpoints.down("md"));

  return time ? (
    <TimelineItem>
      <TimelineOppositeContent sx={oppositeSx}>
        <Typography variant="h6" component="span" style={(smBreakPoint) ? {fontSize:18} : {}}>
          {!title
            ? capitalize(
                t(`cremationStatus${status}`, { ns: "resourceCremation" })
              )
            : title}
        </Typography>
        {location && (
          <Typography
            sx={{
              display: "flex ",
              alignItems: "center",
              justifyContent: "end",
              color: "#707070",
            }}
            style={{ fontSize: (smBreakPoint) ? 12 : 19,fontWeight:'bold' }}
>
            {locationOverride ? locationOverride : location}
          </Typography>
        )}
        { managedBy &&
          <div style={{fontSize:14,display:'flex',alignItems:'center',justifyContent:'end',color:'#909090',gap:2}}>
          <People sx={{fontSize:14}}/>{managedBy}
          </div>
        }
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot sx={{ backgroundColor: CremationStatusColor[status] }}>
          <CremationStatusIcon status={status} />
        </TimelineDot>
        {!last && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent sx={contentSx}>
        <Card
          sx={{
            color: (theme) => theme.palette.grey[700],
          }}
        >
          <CardContent>
            <StyledBox style={{fontSize: smBreakPoint ? 14 : 22}}>
              <TodayIcon />
              {smBreakPoint ? format(new Date(time), "dd.MM.yyyy", { locale: it }) :  capitalize(
                format(new Date(time), "EEEE dd LLLL yyyy", { locale: it })
              )}
            </StyledBox>
            <StyledBox style={{fontSize: smBreakPoint ? 14 : 22}}>
              <AccessTimeIcon />
              {forceOneHourLess
                ? capitalize(
                    formatInTimeZone(time, "Europe/London", "HH:mm", {
                      locale: it,
                    })
                  )
                : capitalize(format(new Date(time), "HH:mm", { locale: it }))}
            </StyledBox>
            {detailItems.map((item) => (
              <StyledBox key={nextId()}  style={{fontSize: smBreakPoint ? 14 : 22}}>{item}</StyledBox>
            ))}

            {qrScanTime && (
                <StyledBox sx={{fontSize:14, color:'#909090', display:'flex', justifyContent: (smBreakPoint) ? `start` : 'end',margin:0,alignItems:'center'}}>
                <QrCode2 sx={{fontSize:14}}/>{`
   
                ${smBreakPoint ? format(new Date(qrScanTime), "dd.MM.yyyy", {
                  locale: it,
                }) : `Scansione il  ${format(new Date(qrScanTime), "dd.MM.yyyy", {
                  locale: it,
                })} alle ${format(new Date(qrScanTime), "HH:mm", {
                  locale: it,
                })} a ${location}`}`}
                </StyledBox>
            )}
          </CardContent>
        </Card>
      </TimelineContent>
    </TimelineItem>
  ) : null;
};
