import { FC, Fragment, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GraphqlFormError, hasGraphqlErrorSlim } from './graphl-form-error';
import { NukFormContext } from './nuk-form-context';
import { NukFormError } from "./nuk-form-error";
import { Skeleton } from "@mui/material";

type Props = {
    name: string
    id?: string
    rules?: any
}

/**
 * Hidden Field Component wrapped in a React Hook Form Controller for maximum compatibility.
 * id: if not specified, is the same as name
 * @param props 
 * @returns 
 */
export const NukFormHiddenField : FC<Props> = (
  { name,
    id,
    rules,
  }) => {
    if(id==null) id = name
    const { control,formState: { errors }} = useFormContext()
    const { loading,gqlErrors } = useContext(NukFormContext)
    const component =         
    <Fragment> 
      <Controller
              name={name}
              control={control}
              defaultValue=""
              rules={rules}
              render={({ field: { onChange, value } }) => (
                <input
                  type="hidden"
                  id={id}
                  value={value}
                  onChange={onChange}
                />
              )}
      />
      <NukFormError field={name} errors={errors} />
      <GraphqlFormError errors={gqlErrors} filters={[name]}/>
    </Fragment>

    const skeleton = <Skeleton animation="wave" height={100}/>

    return ((!loading) ? component : skeleton)
} 