import { Box, Typography } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { CremationStatus } from "../../../../constants/cremation-status.enum";
import { CremationStatusChip } from "../../../chips/cremation-status.chip";
import { Inventory } from "@mui/icons-material";
import { Person } from "@mui/icons-material";
import { StyledBadge } from "../cremations-list.columns";

/**
 * Cremation list detail column
 * (Used for xsBreakPoint to provide a better mobile experience)
 * ? This should become obsolete once MUI implements a default collapse in MuiDataGridProd
 * @param t 
 * @returns 
 */
export const cremationListDetailColumn = (t: Function) => {
  return {
    field: 'name',
    headerName: t('details', { ns: 'general' }),
    sortable: false,
    flex:1,
    renderCell: (params: GridRowParams) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pl: 1 }}>
   {params.row.type==='MEDICAL_REMAINS' ? 
          
          <Typography variant="h6" textTransform="uppercase" fontWeight="bold" sx={{display:'flex',alignItems:'center'}}>
            <StyledBadge badgeContent={(params.row.packets) ? params.row.packets.reduce((a : any,v : any) =>  a = a + v.total , 0 ) : 0} sx={{mr:2}}>
            <Inventory sx={{color:'rgba(0,0,0,0.5)'}} />
          </StyledBadge>
            Residui Medici
          
          </Typography>

          : 
          
          <Typography variant="h6" textTransform="uppercase" fontWeight="bold" sx={{display:'flex',alignItems:'center'}}>
            <Person sx={{color:'rgba(0,0,0,0.5)',mr:2}}/>
            {params.row.lastName} {params.row.firstName} </Typography>
          }
          <div>
          <CremationStatusChip value={params.row.status as CremationStatus}/>
        
        </div></Box>
      )
    }
  }
}

export const cremationListDetailColumnHeight = 70