import { capitalize as capitalizeFunc, Typography } from "@mui/material"
import { FC, Fragment } from "react"
import { NukLabel } from "./nuk-label"
import { textTransform } from '@mui/system';

type Props = {
    label: string,
    value: string,
    capitalize?: boolean
    fontSize?: number,
    icon?: JSX.Element
    fontWeight?: string,
    textTransform?: "uppercase" | "lowercase",
    color?: string
}

export const NukTextViewLabel : FC<Props> = ({
    label,
    value = "",
    capitalize = true,
    fontSize = 26,
    fontWeight = "normal",
    textTransform,
    icon,
    color
}) =>{
    return(
        <Fragment>
            <NukLabel label={label}/>
            <Typography 
                fontSize={fontSize} 
                fontWeight={fontWeight} 
                textTransform={(textTransform) ? textTransform : "none"}
                color={color}
            >
                {icon} {(capitalize) ? capitalizeFunc(value) : value}
            </Typography>
        </Fragment>
    )
}