import { FC } from "react";
import { NukFormLabel } from "../../../../core/nuk-components";
import { FormLabel, Typography } from "@mui/material";

type Props = {
    value: string | null ;
};
export const DeliveryPipelineLabel: FC<Props> = ({ value}) => {
  return <div style={{marginTop:10}}>
    <Typography sx={{mb: 1}}>Luogo di consegna </Typography>
    <div style={{fontWeight:'bold'}}>
    {(value && value === "CFL") ? 
      <>
        <Typography fontWeight={'bold'}>Centro Funerario Lugano</Typography>
        <Typography>Via delle Rose 4</Typography>
        <Typography>6963 Pregassona</Typography>
      </>
      :
      <>
        <Typography fontWeight={'bold'}>Crematorio Ticino</Typography>
        <Typography>Via Riale Righetti 26</Typography>
        <Typography>6503 Bellinzona-Carasso</Typography>
      </>
    }
    </div>
  </div>;
};
