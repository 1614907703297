import { FC } from 'react';
import { capitalize, Card, CardContent, Typography } from '@mui/material';
import { CremationStatus, CremationStatusColor } from '../../constants/cremation-status.enum';
import { useTranslation } from 'react-i18next';
import { CremationStatusIcon } from '../icons/cremation-status.icon';

type Props = {
    value: CremationStatus 
    detail?: string
    location?: string
}

export const CremationStatusCard: FC<Props> = ({
    value,
    detail = null,
    location = null,
}) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ backgroundColor: CremationStatusColor[value], color: '#FFF' }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center',justifyContent:'center' }}>
                <CremationStatusIcon status={value} />
                <Typography variant="h6" component="div" sx={{ ml: 1 }}>
                    {capitalize(t(`cremationStatus${value}`, { ns: 'resourceCremation' }))}
                    {detail ? ` ${detail}` : ''}
                    {location ? ` (${location})` : ''}
                </Typography>
            </CardContent>
        </Card>
    );
} 