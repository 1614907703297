import { Close, InfoOutlined, QrCode2Outlined } from "@mui/icons-material";
import { Alert, Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { format } from "date-fns-tz";
import { FC, useState } from "react";
import { NukButton } from "../../../../../core/components/buttons/nuk-button";
import QRCode from "react-qr-code";
import { NukLabel } from "../../../../../core/components/labels/nuk-label";
import { NukTextViewLabel } from "../../../../../core/nuk-components";

type Props = {
  value: any;
  qrId: string;
  qrCode: string;
  qrVariable: string;
  label: string;
};
export const CremationEditorScanRecapBlock: FC<Props> = ({
  value,
  label,
  qrId,
  qrCode,
  qrVariable,
}) => {
  const [qrDetailOpen, setQrDetailOpen] = useState(false);

  return (
    <Grid item sm={12}>
      <Grid container sx={{}}>
        <Grid item xs={10}>
          {value ? (
                  <Typography sx={{color:'#4caf50',fontWeight:'bold'}}>
              Scan di {label} eseguito il{" "}
              <b>{format(new Date(value), "dd.MM.yyyy")}</b>{" "}
              alle{" "}
              <b>{format(new Date(value), "HH:mm")}</b>
            </Typography>
          ) : (
            <Typography sx={{color:'#999'}}>
              Lo scan di <b>{label}</b> non è ancora stato effettuato.
            </Typography>
          )}
        </Grid>
        <Grid
          item
          sm={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <NukButton
            label={`${qrDetailOpen ? `Nascondi` : `Mostra`} QR`}
            icon={<QrCode2Outlined />}
            size="small"
            onClick={() => {
              setQrDetailOpen((previous) => !previous);
            }}
          />
        </Grid>
        {qrDetailOpen && (
          <Grid item sm={12}>
            <Card>
              <CardContent sx={{ display: "flex", justifyContent: "start" }}>
                {qrCode ? (
                  <QRCode value={qrCode} size={256} />
                ) : (
                  <Box 
                    sx={{
                      width: 256,
                      height: 256,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #ccc',
                      backgroundColor: '#e0e0e0'
                    }}
                  >
                    <Close sx={{ fontSize: 48, color: '#999', mb: 2 }} />
                    <Typography color="#999">QR non ancora generato</Typography>
                  </Box>
                )}
                <Box sx={{ marginLeft: 2 }}>
                  <NukTextViewLabel value={qrId || `N/A`} label={"ID UNIVOCO"} capitalize={false} color={!qrId ? "#999" : undefined}/>

                  <NukLabel label={`HASH`}/>

                    <Box style={{ marginLeft: 2, overflowWrap: 'break-word', width: '600px', fontSize:'16px', color: !qrCode ? '#999' : undefined }}>
                    {qrCode || 'N/A'}
                    </Box>
                    <NukTextViewLabel value={qrVariable || `N/A`} label={"VARIABILE"} capitalize={false}/>

                </Box>
              
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
function setState(arg0: boolean): [any, any] {
  throw new Error("Function not implemented.");
}
