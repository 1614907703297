import { Alert, Card, CardContent, Chip, Grid } from "@mui/material";
import { FC } from "react";
import { NukLabelTitle } from "../../../../../core/components/labels/nuk-label-title";
import { CorporateFare } from "@mui/icons-material";
import { NukLabel } from "../../../../../core/components/labels/nuk-label";
import { NukFormDateField } from "../../../../../core/components/forms/nuk-form-date-field";
import { NukFormSelectField } from "../../../../../core/components/forms/nuk-form-select-field";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import EventIcon from "@mui/icons-material/Event";
import InfoIcon from "@mui/icons-material/Info";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import { NukTimeUtils } from "../../../../../core/utils/nuk-time-utils";
import { format } from "date-fns";
import { CremationEditorFormBlockableEvent } from "./components/cremation-editor-form-blockable-event";
import { NukFormTextField } from "../../../../../core/nuk-components";
import { CremationEditorScanRecapBlock } from "./cremation-editor-scan-recap-block";

type Props = {
  resourceData: any;
};
export const CremationEditorCFLBodyBlock: FC<Props> = ({ resourceData }) => {
  return (
    <Grid item sm={12}
>
      <Grid container columnSpacing={2} rowSpacing={2}
          sx={{ paddingLeft: 2,paddingTop:4 }}

      >
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item md={12}>
                <NukLabelTitle
                  label="Consegna Salma - Pregassona - Stabile CFL"
                  icon={<CorporateFare />}
                ></NukLabelTitle>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardContent>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item md={12}>
                        <NukLabel
                          label="Consegna Prevista"
                          icon={<EventIcon />}
                        ></NukLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NukFormDateField
                          name="estimatedCflDeliveryAtDate"
                          label="Data"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NukFormSelectField
                          name={"estimatedCflDeliveryAtTime"}
                          items={NukTimeUtils.getTimeValuesForSelect({
                            startHour: 0,
                            endHour: 23,
                            minuteBreak: 15,
                          })}
                          label={"Ora"}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardContent>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item md={12}>
                        <NukLabel
                          label="Cella"
                          icon={<AcUnitIcon />}
                        ></NukLabel>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <NukFormSelectField
                          name="enteredCflCell"
                          label="Cella"
                          items={[
                            {
                              value: 101,
                              label: "Pregassona CFL 1",
                              renderValue: (
                                <Chip
                                  icon={<AcUnitIcon />}
                                  label="1"
                                  sx={{
                                    color: "#FFF",
                                    backgroundColor: "#038ffb",
                                    "& .MuiChip-icon": {
                                      color: "#FFF",
                                    },
                                  }}
                                />
                              ),
                            },
                            {
                              value: 102,
                              label: "2",
                              renderValue: (
                                <Chip
                                  icon={<AcUnitIcon />}
                                  label="2"
                                  sx={{
                                    color: "#FFF",
                                    backgroundColor: "#038ffb",
                                    "& .MuiChip-icon": {
                                      color: "#FFF",
                                    },
                                  }}
                                />
                              ),
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardContent>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item md={12}>
                        <NukLabel
                          label="Entrata in cella"
                          icon={<AcUnitIcon />}
                        ></NukLabel>
                      </Grid>

                      <Grid item xs={12} sm={12} md={4} lg={6}>
                        <NukFormDateField
                          name="enteredCflCellAtDate"
                          label="Data"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={6}>
                        <NukFormSelectField
                          name="enteredCflCellAtTime"
                          items={NukTimeUtils.getTimeValuesForSelect({
                            startHour: 0,
                            endHour: 23,
                            minuteBreak: 1,
                          })}
                          label={"Ora"}
                        />
                      </Grid>
                      <CremationEditorScanRecapBlock
                        value={resourceData?.cremation.deliveryQrCflScannedAt}
                        label="consegna"
                        qrId={resourceData?.cremation.deliveryQrCflId}
                        qrCode={resourceData?.cremation.deliveryQrCflCode}
                        qrVariable={`deliveryQrCflId / deliveryQrCflCode`}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardContent>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item md={12}>
                        <NukLabel
                          label="Uscita dalla cella"
                          icon={<AcUnitIcon />}
                        ></NukLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NukFormDateField
                          name="exitedCflCellAtDate"
                          label="Data"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NukFormSelectField
                          name={"exitedCflCellAtTime"}
                          items={NukTimeUtils.getTimeValuesForSelect({
                            startHour: 0,
                            endHour: 23,
                            minuteBreak: 1,
                          })}
                          label={"Ora"}
                        />
                      </Grid>
                      <CremationEditorScanRecapBlock
                        value={
                          resourceData?.cremation
                            .pickupQrCflScannedAt
                        }
                        qrId={resourceData?.cremation.deliveryQrCflId}
                        qrCode={resourceData?.cremation.deliveryQrCflCode}
                        qrVariable={`deliveryQrCflId / deliveryQrCflCode`}
                        label="ritiro"
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
