import {
  Box,
  capitalize,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CremationStatus, isStatusEqualOrAfter } from "../../constants/cremation-status.enum";
import { CremationStatusStepLabel } from "./cremation-status.step-label";

type CremationStatusStepperProps = {
  status: CremationStatus;
  deliveryPipeline: string | null | undefined;
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#4caf50",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    marginTop: 5,
    borderRadius: 1,
  },
}));

export const CremationStatusStepper: FC<CremationStatusStepperProps> = ({
  status,
  deliveryPipeline,
}) => {
  return (
    <Stepper
      alternativeLabel
      orientation="horizontal"
      sx={{ flex: 1 }}
      connector={<QontoConnector />}
    >
      <Step completed={isStatusEqualOrAfter(status, CremationStatus.REGISTERED)}>
        <CremationStatusStepLabel status={CremationStatus.REGISTERED} 
        detail="Portale Online"
        />
      </Step>
      {deliveryPipeline === "CFL" && (
        <Step completed={isStatusEqualOrAfter(status, CremationStatus.DELIVERED_CFL)}>
          <CremationStatusStepLabel
            status={CremationStatus.DELIVERED_CFL}
            detail="Pregassona"
          />
        </Step>
      )}
      {deliveryPipeline === "CFL" && (
        <Step completed={isStatusEqualOrAfter(status, CremationStatus.MOVING_FROM_CFL)}>
          <CremationStatusStepLabel
            status={CremationStatus.MOVING_FROM_CFL}
            title={status == CremationStatus.MOVING_FROM_CFL ? `Trasporto in corso` : `Trasporto`}
            detail="Verso Bellinzona"
          />
        </Step>
      )}


      <Step completed={isStatusEqualOrAfter(status, CremationStatus.DELIVERED)}>
        <CremationStatusStepLabel
          status={CremationStatus.DELIVERED}
          detail="Carasso"
        />
      </Step>

      <Step completed={isStatusEqualOrAfter(status, CremationStatus.STARTED)}>
        <CremationStatusStepLabel status={CremationStatus.STARTED} detail="Carasso" />
      </Step>

      <Step completed={isStatusEqualOrAfter(status, CremationStatus.COMPLETED)}>
        <CremationStatusStepLabel
          status={CremationStatus.COMPLETED}
          detail="Carasso"
        />
      </Step>

      {(deliveryPipeline==='BZONA') && 
      <Step completed={isStatusEqualOrAfter(status, CremationStatus.COLLECTED)}>
        <CremationStatusStepLabel
          status={CremationStatus.COLLECTED}
          detail="Carasso"
        />
      </Step>
      }


      {deliveryPipeline === "CFL" && (
        <Step completed={isStatusEqualOrAfter(status, CremationStatus.MOVING_TO_CFL)}>
          <CremationStatusStepLabel
            status={CremationStatus.MOVING_TO_CFL}
            title={status == CremationStatus.MOVING_TO_CFL ? `Trasporto in corso` : `Trasporto`}
            detail="Verso Pregassona"
          />
        </Step>
      )}

      {(deliveryPipeline==='CFL') && 
      <Step completed={isStatusEqualOrAfter(status, CremationStatus.DELIVERED_BACK_CFL)}>
        <CremationStatusStepLabel
          status={CremationStatus.DELIVERED_BACK_CFL}
          detail="Pregassona"
        />
      </Step>
}
      

      {deliveryPipeline === "CFL" && (
        <Step completed={isStatusEqualOrAfter(status, CremationStatus.COLLECTED_CFL)}>
          <CremationStatusStepLabel
            status={CremationStatus.COLLECTED_CFL}
            detail="Pregassona"
          />
        </Step>
      )}
    </Stepper>
  );
};

const isCompleted = (
  currentStatus: CremationStatus,
  compareStatus: CremationStatus
) => {
  switch (compareStatus) {
    case CremationStatus.REGISTERED:
      return true;
    case CremationStatus.DELIVERED_CFL:
      return [
        CremationStatus.DELIVERED_CFL,
        CremationStatus.DELIVERED,
        CremationStatus.STARTED,
        CremationStatus.COMPLETED,
        CremationStatus.COLLECTED_CFL,
        CremationStatus.COLLECTED,
        CremationStatus.COLLECTED_CFL,
      ].includes(currentStatus)
        ? true
        : false;
    case CremationStatus.DELIVERED:
      return [
        CremationStatus.DELIVERED,
        CremationStatus.STARTED,
        CremationStatus.COMPLETED,
        CremationStatus.COLLECTED,
        CremationStatus.COLLECTED_CFL,
        CremationStatus.COLLECTED_CFL,
      ].includes(currentStatus)
        ? true
        : false;
    case CremationStatus.STARTED:
      return [
        CremationStatus.STARTED,
        CremationStatus.COMPLETED,
        CremationStatus.COLLECTED,
        CremationStatus.COLLECTED_CFL,
        CremationStatus.COLLECTED_CFL,
      ].includes(currentStatus)
        ? true
        : false;
    case CremationStatus.COMPLETED:
      return [
        CremationStatus.COMPLETED,
        CremationStatus.COLLECTED,
        CremationStatus.COLLECTED_CFL,
        CremationStatus.COLLECTED_CFL,
      ].includes(currentStatus)
        ? true
        : false;
    case CremationStatus.COLLECTED:
      return [
        CremationStatus.COLLECTED,
        CremationStatus.COLLECTED_CFL,
        CremationStatus.COLLECTED_CFL,


      ].includes(currentStatus) ? true : false;
      case CremationStatus.COLLECTED_CFL:
        return [
          CremationStatus.COLLECTED_CFL,
          CremationStatus.COLLECTED_CFL,
  
  
        ].includes(currentStatus) ? true : false;
        case CremationStatus.COLLECTED_CFL:
          return [
            CremationStatus.COLLECTED_CFL,
    
    
          ].includes(currentStatus) ? true : false;
    default:
      return false;
  }
};
