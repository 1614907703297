import { Alert, Card, CardContent, Chip, Grid } from "@mui/material";
import { FC } from "react";
import { NukLabelTitle } from "../../../../../core/components/labels/nuk-label-title";
import { CorporateFare } from "@mui/icons-material";
import { NukLabel } from "../../../../../core/components/labels/nuk-label";
import { NukFormDateField } from "../../../../../core/components/forms/nuk-form-date-field";
import { NukFormSelectField } from "../../../../../core/components/forms/nuk-form-select-field";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import EventIcon from "@mui/icons-material/Event";
import InfoIcon from "@mui/icons-material/Info";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import { NukTimeUtils } from "../../../../../core/utils/nuk-time-utils";
import { format } from "date-fns";
import { CremationEditorFormBlockableEvent } from "./components/cremation-editor-form-blockable-event";
import { NukFormTextField } from "../../../../../core/nuk-components";
import { CremationEditorScanRecapBlock } from "./cremation-editor-scan-recap-block";

type Props = {
    resourceData: any
    watchMedicalCertificateSigned: any
};
export const CremationEditorCFLAshesBlock: FC<Props> = ({resourceData,watchMedicalCertificateSigned}) => {
  return          <Grid item sm={12}>
  <Grid
    container
    columnSpacing={2}
    rowSpacing={2}
    sx={{ marginTop:1 }}

  >
      <Grid item sm={12}>
    <Card>
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item md={12}>
            <NukLabelTitle
              label="Pregassona - Stabile CFL"
              icon={<CorporateFare />}
            ></NukLabelTitle>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <CremationEditorFormBlockableEvent
              active={watchMedicalCertificateSigned}
              label="Consegna ceneri"
              icon={<TakeoutDiningIcon />}
              alert="Certificato medico necessario."
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={2}
              >
                <Grid item xs={12} md={6}>
                  <NukFormDateField
                    name="backDeliveryQrCflScannedAtDate"
                    label="Data"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NukFormSelectField
                    name={"backDeliveryQrCflScannedAtTime"}
                    items={NukTimeUtils.getTimeValuesForSelect(
                      {
                        startHour: 0,
                        endHour: 23,
                        minuteBreak: 15,
                      }
                    )}
                    label={"Ora"}
                  />{" "}
                </Grid>
  

                <Grid item md={12}>
                <CremationEditorScanRecapBlock
                      value={resourceData?.cremation.backDeliveryQrCflScannedAt}
                      label="consegna" 
                      qrId={resourceData?.cremation.collectionQrCflId}
                      qrCode={resourceData?.cremation.collectionQrCflCode}
                      qrVariable={`collectionQrCflId / collectionQrCflCode`}
                    /> 
                </Grid>
              </Grid>
            </CremationEditorFormBlockableEvent>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CremationEditorFormBlockableEvent
              active={watchMedicalCertificateSigned}
              label="Ritiro ceneri"
              icon={<TakeoutDiningIcon />}
              alert="Certificato medico necessario."
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={2}
              >
                <Grid item xs={12} md={3}>
                  <NukFormDateField
                    name="collectionQrCflScannedAtDate"
                    label="Data"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <NukFormSelectField
                    name={"collectionQrCflScannedAtTime"}
                    items={NukTimeUtils.getTimeValuesForSelect(
                      {
                        startHour: 0,
                        endHour: 23,
                        minuteBreak: 15,
                      }
                    )}
                    label={"Ora"}
                  />{" "}
                </Grid>
                <Grid item xs={12} md={6}>
                  <NukFormTextField
                    name="collectedBy"
                    label="Persona incaricata"
                    sx={{ mt: 0 }}
                  />
                </Grid>

                <Grid item md={12}>
                <CremationEditorScanRecapBlock
                      value={resourceData?.cremation.collectionQrCflScannedAt}
                      label="ritiro" 
                      qrId={resourceData?.cremation.collectionQrCflId}
                      qrCode={resourceData?.cremation.collectionQrCflCode}
                      qrVariable={`collectionQrCflId / collectionQrCflCode`}
                    /> 
                </Grid>
              </Grid>
            </CremationEditorFormBlockableEvent>
          </Grid>

        
        </Grid>
      </CardContent>
    </Card>
    </Grid>
    </Grid>
</Grid>;
};
