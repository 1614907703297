import { FC } from "react";
import { CremationStatus } from "../../constants/cremation-status.enum";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddTaskIcon from "@mui/icons-material/AddTask";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Inventory, LocalShippingRounded, Verified } from "@mui/icons-material";
type Props = {
  status: CremationStatus | string | undefined;
};

export const CremationStatusIcon: FC<Props> = ({ status }) => {
  switch (status) {
    case CremationStatus.REGISTERED:
      return <MoveToInboxIcon />;
    case CremationStatus.DELIVERED_CFL:
      return <AcUnitIcon />;
    case CremationStatus.MOVING_FROM_CFL:
      return <LocalShippingRounded />;
    case CremationStatus.DELIVERED:
      return <AcUnitIcon />;
    case CremationStatus.STARTED:
      return <LocalFireDepartmentIcon />;
    case CremationStatus.COMPLETED:
      return <Inventory />;
    case CremationStatus.COLLECTED:
      return <Verified />;
      case CremationStatus.MOVING_TO_CFL:
        return <LocalShippingRounded />;
        case CremationStatus.DELIVERED_BACK_CFL:
          return <Inventory />;
    case CremationStatus.COLLECTED_CFL:
      return <Verified />;
  }

  return <HelpOutlineIcon />;
};
