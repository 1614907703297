import { FC } from "react";
import { NukFormLabel } from "../../../../core/nuk-components";
import { Box, FormLabel, Typography } from "@mui/material";

type Props = {
    value: string | null ;
    register: any;
};
export const DeliveryPipelinePicker: FC<Props> = ({ value,register}) => {
  return <div style={{marginTop:10}}>
    <FormLabel style={{fontWeight:'bold'}}>Luogo di consegna </FormLabel>
    <div style={{fontWeight:'bold'}}>
    {(value && value == "CFL") ? 
    <Box>
      <Typography fontWeight={'bold'}>Centro Funerario Lugano</Typography>
      <Typography>Via delle Rose 4</Typography>
      <Typography>6963 Pregassona</Typography>
    </Box>
      :
      <Box>
        <Typography fontWeight={'bold'}>Crematorio Ticino</Typography>
        <Typography>Via Riale Righetti 26</Typography>
        <Typography>6503 Bellinzona-Carasso</Typography>
      </Box>
    }

    </div>
  <input type={"hidden"} {...register("deliveryPipeline")} value={(value) ? value : 'BZONA'} />
  </div>;
};
