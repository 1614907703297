//REGISTERED
//DELIVERED
//STARTED
//COMPLETED
//COLLECTED
import { blueGrey } from '@mui/material/colors'

export enum CremationStatus {
    REGISTERED = "REGISTERED",
    DELIVERED_CFL = "DELIVERED_CFL",
    MOVING_FROM_CFL = "MOVING_FROM_CFL",
    DELIVERED = "DELIVERED",
    STARTED = "STARTED",
    COMPLETED = "COMPLETED",
    COLLECTED = "COLLECTED",
    MOVING_TO_CFL = "MOVING_TO_CFL",
    DELIVERED_BACK_CFL = "DELIVERED_BACK_CFL",
    COLLECTED_CFL = "COLLECTED_CFL"
}


export enum CremationStatusColor {
    REGISTERED = '#37474f', 
    DELIVERED_CFL = '#2196f3',
    MOVING_FROM_CFL = '#9c27b0',
    DELIVERED = '#2196f3',
    STARTED = '#d80000',
    COMPLETED = '#ff8b2c',
    COLLECTED = '#388e3c',
    MOVING_TO_CFL = '#9c27b0',
    DELIVERED_BACK_CFL = '#ff8b2c',
    COLLECTED_CFL = '#388e3c'
}

export const getCremationStatusOrder = (status: CremationStatus): number => {
    const statusOrder = Object.values(CremationStatus);

    const index = statusOrder.indexOf(status);
    return index === -1 ? -1 : index + 1;
};

export const isStatusBefore = (status1: CremationStatus, status2: CremationStatus): boolean => {
    return getCremationStatusOrder(status1) < getCremationStatusOrder(status2);
};

export const isStatusAfter = (status1: CremationStatus, status2: CremationStatus): boolean => {
    return getCremationStatusOrder(status1) > getCremationStatusOrder(status2);
};

export const isStatusEqualOrBefore = (status1: CremationStatus, status2: CremationStatus): boolean => {
    return getCremationStatusOrder(status1) <= getCremationStatusOrder(status2);
};

export const isStatusEqualOrAfter = (status1: CremationStatus, status2: CremationStatus): boolean => {
    return getCremationStatusOrder(status1) >= getCremationStatusOrder(status2);
};


