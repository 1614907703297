import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin  from '@fullcalendar/resource-timeline';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCremationsByCellOccupationLazyQuery } from '../../../graphql/nuk-graphql-main';

type Props = {}


type Event = {
  resourceId:string,
  title: string,
  start: Date,
  end: Date,
}

const extractEvents = (data:any) => {
  
  const bzonaEvents = data.getCremationsByCellOccupation.map((c: any) => {
    return{
    resourceId:(c.enteredCell) ? c.enteredCell.toString() : "",
    title: (c.type!=='MEDICAL_REMAINS') ? `${c.lastName} ${c.firstName}` : `Residui medici`,
    start: c.enteredCellAt,
    end: c.exitedCellAt
  }
  })

  const cflEvents = data.getCremationsByCellOccupation.filter((c: any) => c.deliveryPipeline === 'CFL').map((c: any) => {
    return{
    resourceId:(c.enteredCflCell) ? c.enteredCflCell.toString() : "",
    title: (c.type!=='MEDICAL_REMAINS') ? `${c.lastName} ${c.firstName}` : `Residui medici`,
    start: c.enteredCflCellAt,
    end: c.exitedCflCellAt
  }
  })
  console.log([...bzonaEvents, ...cflEvents])
  return [...bzonaEvents, ...cflEvents];
}

export const DashboardDailyTimeline : FC<Props> = ({}) =>{
    const {t,i18n} = useTranslation()
    const [query,{data,loading,error}] = useGetCremationsByCellOccupationLazyQuery()
    const [events,setEvents] = useState<Event[]>([])
    useEffect(() =>{

    },[])

    useEffect(() =>{
      if(data){
        setEvents(extractEvents(data))
      }
    },[data,loading])
    return(
                 
        <FullCalendar
        height={'auto'}

      plugins={[ resourceTimelinePlugin ]}
      datesSet={(arg: any)=>{
        query({variables:{
          input:{
            start: arg.start,
            end: arg.end
          }
        }})
      }}
      resources={[
        {"id": "1","title": "Cella 1","eventColor": "rgba(56,142,60,1)","location": "Carasso"},
        {"id": "2","title": "Cella 2","eventColor": "rgba(56,142,60,1)","location": "Carasso"},
        {"id": "3","title": "Cella 3","eventColor": "rgba(56,142,60,1)","location": "Carasso"},
        {"id": "4","title": "Cella 4","eventColor": "rgba(56,142,60,1)","location": "Carasso"},
        {"id": "5","title": "Cella 5","eventColor": "rgba(56,142,60,1)","location": "Carasso"},
        {"id": "6","title": "Cella 6","eventColor": "rgba(56,142,60,1)","location": "Carasso"},
        {"id": "7","title": "Cella 7","eventColor": "rgba(56,142,60,1)","location": "Carasso"},
        {"id": "8","title": "Cella 8","eventColor": "rgba(56,142,60,1)","location": "Carasso"},
        {"id": "9","title": "Cella 9","eventColor": "rgba(56,142,60,1)","location": "Carasso"},
        {"id": "101","title": "Cella 1 ","eventColor": "rgba(42,80,232,1)","location": "Pregassona"},
        {"id": "102","title": "Cella 2","eventColor": "rgba(42,80,232,1)","location": "Pregassona"}
      ]}
      events={events}
      slotMinTime="08:00"
      slotMaxTime="18:00"
      slotDuration="00:01:00"
      slotMinWidth={20}
      resourceLabelContent={(arg) => (
        <div style={{color: arg.resource.eventBackgroundColor,display:'flex',alignItems:'center',gap:5}}>
          <div style={{fontSize:14,fontWeight:'bold'}}>{arg.resource.title} </div>
          <div style={{fontSize:10}}>{arg.resource.extendedProps.location}</div>
        </div>
      )}
      stickyHeaderDates
      initialView="resourceTimelineWeek"
      resourceAreaHeaderContent="Permanenze"
      firstDay={1}
      resourceAreaWidth={150}
      nowIndicator={true}
      locale={i18n.language}
      buttonText={{
        today:    'oggi',
      }}
      resourceOrder={""}
      slotLabelFormat={[
        { weekday: 'long', day: 'numeric' },
        {hour: '2-digit',
        hour12:false}, // top level of text

      ]}
      schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
    />
    )
} 