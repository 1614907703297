import { Alert, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { NukLabelTitle } from "../../../../../core/components/labels/nuk-label-title";
import { CorporateFare } from "@mui/icons-material";
import { NukLabel } from "../../../../../core/components/labels/nuk-label";
import { NukFormDateField } from "../../../../../core/components/forms/nuk-form-date-field";
import { NukFormSelectField } from "../../../../../core/components/forms/nuk-form-select-field";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import EventIcon from "@mui/icons-material/Event";
import InfoIcon from "@mui/icons-material/Info";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import { NukTimeUtils } from "../../../../../core/utils/nuk-time-utils";
import { format } from "date-fns";
import { CremationEditorFormBlockableEvent } from "./components/cremation-editor-form-blockable-event";
import { NukFormTextField } from "../../../../../core/nuk-components";
import { CremationEditorScanRecapBlock } from "./cremation-editor-scan-recap-block";

type Props = {
    resourceData: any
    watchMedicalCertificateSigned: any
};
export const CremationEditorFormBellinzonaBlock: FC<Props> = ({resourceData,watchMedicalCertificateSigned}) => {
  return          <Grid item sm={12}>
  <Grid
    container
    columnSpacing={2}
    rowSpacing={2}
    sx={{ paddingLeft: 2,paddingTop:2,marginTop:1 }}
  >
    <Card>
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item md={12}>
            <NukLabelTitle
              label="Cremazione - Bellinzona - Crematorio"
              icon={<CorporateFare />}
            ></NukLabelTitle>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardContent>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={2}
                >
                  <Grid item md={12}>
                    <NukLabel
                      label="Consegna Prevista"
                      icon={<EventIcon />}
                    ></NukLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <NukFormDateField
                      name="estimatedDeliveryAtDate"
                      label="Data"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <NukFormSelectField
                      name={"estimatedDeliveryAtTime"}
                      items={NukTimeUtils.getTimeValuesForSelect(
                        {
                          startHour: 0,
                          endHour: 23,
                          minuteBreak: 15,
                        }
                      )}
                      label={"Ora"}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardContent>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={2}
                >
                  <Grid item md={12}>
                    <NukLabel
                      label="Cella"
                      icon={<AcUnitIcon />}
                    ></NukLabel>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <NukFormSelectField
                      name="enteredCell"
                      label="Cella"
                      items={[
                        {
                          value: 1,
                          label: "1",
                          renderValue: (
                            <Chip
                              icon={<AcUnitIcon />}
                              label="1"
                              sx={{
                                color: "#FFF",
                                backgroundColor: "#038ffb",
                                "& .MuiChip-icon": {
                                  color: "#FFF",
                                },
                              }}
                            />
                          ),
                        },
                        {
                          value: 2,
                          label: "2",
                          renderValue: (
                            <Chip
                              icon={<AcUnitIcon />}
                              label="2"
                              sx={{
                                color: "#FFF",
                                backgroundColor: "#038ffb",
                                "& .MuiChip-icon": {
                                  color: "#FFF",
                                },
                              }}
                            />
                          ),
                        },
                        {
                          value: 3,
                          label: "3",
                          renderValue: (
                            <Chip
                              icon={<AcUnitIcon />}
                              label="3"
                              sx={{
                                color: "#FFF",
                                backgroundColor: "#038ffb",
                                "& .MuiChip-icon": {
                                  color: "#FFF",
                                },
                              }}
                            />
                          ),
                        },
                        {
                          value: 4,
                          label: "4",
                          renderValue: (
                            <Chip
                              icon={<AcUnitIcon />}
                              label="4"
                              sx={{
                                color: "#FFF",
                                backgroundColor: "#038ffb",
                                "& .MuiChip-icon": {
                                  color: "#FFF",
                                },
                              }}
                            />
                          ),
                        },
                        {
                          value: 5,
                          label: "5",
                          renderValue: (
                            <Chip
                              icon={<AcUnitIcon />}
                              label="5"
                              sx={{
                                color: "#FFF",
                                backgroundColor: "#038ffb",
                                "& .MuiChip-icon": {
                                  color: "#FFF",
                                },
                              }}
                            />
                          ),
                        },
                        {
                          value: 6,
                          label: "6",
                          renderValue: (
                            <Chip
                              icon={<AcUnitIcon />}
                              label="6"
                              sx={{
                                color: "#FFF",
                                backgroundColor: "#038ffb",
                                "& .MuiChip-icon": {
                                  color: "#FFF",
                                },
                              }}
                            />
                          ),
                        },
                        {
                          value: 7,
                          label: "7",
                          renderValue: (
                            <Chip
                              icon={<AcUnitIcon />}
                              label="7"
                              sx={{
                                color: "#FFF",
                                backgroundColor: "#038ffb",
                                "& .MuiChip-icon": {
                                  color: "#FFF",
                                },
                              }}
                            />
                          ),
                        },
                        {
                          value: 8,
                          label: "8",
                          renderValue: (
                            <Chip
                              icon={<AcUnitIcon />}
                              label="8"
                              sx={{
                                color: "#FFF",
                                backgroundColor: "#038ffb",
                                "& .MuiChip-icon": {
                                  color: "#FFF",
                                },
                              }}
                            />
                          ),
                        },
                        {
                          value: 9,
                          label: "9",
                          renderValue: (
                            <Chip
                              icon={<AcUnitIcon />}
                              label="9"
                              sx={{
                                color: "#FFF",
                                backgroundColor: "#038ffb",
                                "& .MuiChip-icon": {
                                  color: "#FFF",
                                },
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card>
              <CardContent>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={2}
                >
                  <Grid item md={12}>
                    <NukLabel
                      label="Entrata in cella"
                      icon={<AcUnitIcon />}
                    ></NukLabel>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={6}>
                    <NukFormDateField
                      name="enteredCellAtDate"
                      label="Data"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={6}>
                    <NukFormSelectField
                      name="enteredCellAtTime"
                      items={NukTimeUtils.getTimeValuesForSelect(
                        {
                          startHour: 0,
                          endHour: 23,
                          minuteBreak: 1,
                        }
                      )}
                      label={"Ora"}
                    />
                  </Grid>

                  <Grid item md={12}>
                    <CremationEditorScanRecapBlock
                      value={resourceData?.cremation.deliveryQrScannedAt}
                      label="consegna" 
                      qrId={resourceData?.cremation.deliveryQrId}
                      qrCode={resourceData?.cremation.deliveryQrCode}
                      qrVariable={`deliveryQrId / deliveryQrCode`}
                    /> 
                
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card>
              <CardContent>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={2}
                >
                  <Grid item md={12}>
                    <NukLabel
                      label="Uscita dalla cella"
                      icon={<AcUnitIcon />}
                    ></NukLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <NukFormDateField
                      name="exitedCellAtDate"
                      label="Data"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <NukFormSelectField
                      name={"exitedCellAtTime"}
                      items={NukTimeUtils.getTimeValuesForSelect(
                        {
                          startHour: 0,
                          endHour: 23,
                          minuteBreak: 1,
                        }
                      )}
                      label={"Ora"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  { resourceData?.cremation.startedAt ? 
                  <Typography sx={{color:'#4caf50',fontWeight:'bold'}}>
                      Cremazione iniziata il{" "} 
              <b>{format(new Date(resourceData?.cremation.startedAt), "dd.MM.yyyy")}</b>{" "}
              alle{" "}
              <b>{format(new Date(resourceData?.cremation.startedAt), "HH:mm")}</b>
            </Typography> : 
                    <Typography>
                        L'uscita dalla cella viene assegnata definitivamente a <b>cremazione iniziata</b>
                        
                      </Typography>}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <CremationEditorFormBlockableEvent
              active={watchMedicalCertificateSigned}
              label="Inizio Cremazione"
              icon={<LocalFireDepartmentIcon />}
              alert="Certificato medico necessario."
            >
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <NukFormDateField
                    name="startedAtDate"
                    label="Data"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <NukFormSelectField
                    name={"startedAtTime"}
                    items={NukTimeUtils.getTimeValuesForSelect(
                      {
                        startHour: 0,
                        endHour: 23,
                        minuteBreak: 1,
                      }
                    )}
                    label={"Ora"}
                  />
                </Grid>
              </Grid>
            </CremationEditorFormBlockableEvent>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <CremationEditorFormBlockableEvent
              active={watchMedicalCertificateSigned}
              label="Fine Cremazione"
              icon={<LocalFireDepartmentIcon />}
              alert="Certificato medico necessario."
            >
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <NukFormDateField
                    name="completedAtDate"
                    label="Data"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <NukFormSelectField
                    name={"completedAtTime"}
                    items={NukTimeUtils.getTimeValuesForSelect(
                      {
                        startHour: 0,
                        endHour: 23,
                        minuteBreak: 1,
                      }
                    )}
                    label={"Ora"}
                  />
                </Grid>
              </Grid>{" "}
            </CremationEditorFormBlockableEvent>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CremationEditorFormBlockableEvent
              active={watchMedicalCertificateSigned}
              label="Ritiro ceneri"
              icon={<TakeoutDiningIcon />}
              alert="Certificato medico necessario."
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={2}
              >
                <Grid item xs={12} md={3}>
                  <NukFormDateField
                    name="collectedAtDate"
                    label="Data"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <NukFormSelectField
                    name={"collectedAtTime"}
                    items={NukTimeUtils.getTimeValuesForSelect(
                      {
                        startHour: 0,
                        endHour: 23,
                        minuteBreak: 15,
                      }
                    )}
                    label={"Ora"}
                  />{" "}
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* IF The delivery pipeline is not BZONA, the collectedBy is not needed here and showed at pregassona */}
                  {resourceData?.cremation.deliveryPipeline == 'BZONA' &&
                  <NukFormTextField
                    name="collectedBy"
                    label="Persona incaricata"
                    sx={{ mt: 0 }}
                  /> }
                </Grid>

                <Grid item md={12}>
                    <CremationEditorScanRecapBlock
                      value={resourceData?.cremation.collectionQrScannedAt}
                      label="ritiro" 
                      qrId={resourceData?.cremation.collectionQrId}
                      qrCode={resourceData?.cremation.collectionQrCode}
                      qrVariable={`collectionQrId / collectionQrCode`}
                    /> 
                
                  </Grid>

              </Grid>
            </CremationEditorFormBlockableEvent>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
</Grid>;
};
